/* These styling was taken from `ctv-web` repo on index.css */
/* Tried to use the Local Font from the next/font/local, but failed */
@postcss-normalize;

@font-face {
  font-family: Godwit;
  src: url(/fonts/godwit/variables/GodwitDisplayVariable-VF.woff2)
    format("woff2-variations");
  font-weight: 100 900;
  font-size: 16pt 48pt;
  line-height: 20pt 56pt;
}

@font-face {
  font-family: Godwit;
  src: url(/fonts/godwit/variables/GodwitTextVariable-VF.woff2)
    format("woff2-variations");
  font-weight: 100 900;
  font-size: 12pt 16pt;
  line-height: 12pt 20pt;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: Godwit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  user-select: none;
}
